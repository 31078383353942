import { Controller } from '@hotwired/stimulus';
import {scale} from "leaflet/src/control/Control.Scale";

export default class extends Controller {
    connect() {
        this.element.addEventListener('chartjs:pre-connect', this._onPreConnect);
        this.element.addEventListener('chartjs:connect', this._onConnect);
    }

    disconnect() {
        // You should always remove listeners when the controller is disconnected to avoid side effects
        this.element.removeEventListener('chartjs:pre-connect', this._onPreConnect);
        this.element.removeEventListener('chartjs:connect', this._onConnect);
    }

    _onPreConnect(event) {
        let customDataY = event.detail.options.scales.y.data;
        let yUnit = customDataY !== undefined ? customDataY.unit : undefined;

        event.detail.options.scales.y.ticks = {
            callback: function(value, index, ticks) {
                return value + (yUnit !== undefined ? ' ' + yUnit : '');
            }
        };

        let customDataX = event.detail.options.scales.y.data;
        let yxUnit = customDataX !== undefined ? customDataX.unit : undefined;

        event.detail.options.scales.y.ticks = {
            callback: function(value, index, ticks) {
                return value + (yxUnit !== undefined ? ' ' + yxUnit : '');
            }
        };
    }

    _onConnect(event) {
        // The chart was just created
        // console.log(event.detail.chart); // You can access the chart instance using the event details

        /*event.detail.chart.options.onHover = (mouseEvent) => {

        };
        event.detail.chart.options.onClick = (mouseEvent) => {

        };*/
    }
}
