import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {

    static targets = [
        'checkboxDisable',
        'checkboxDisableInput',
    ]

    static values = {
    }

    async connect() {
        if (this.hasCheckboxDisableTarget && this.hasCheckboxDisableInputTarget)
        {
            this.checkboxDisableInput($(this.checkboxDisableTarget), $(this.checkboxDisableInputTarget))
        }
    }

    resetFormData() {
        $('input:text').val('');
        $('select').val('');
        $('input:checkbox').removeAttr('checked', 'checked').prop('checked', false);
        $('#sort_form_limit').val(10);
    }

    checkboxDisableInput(checkbox, disableInput) {
        checkStatus();
        checkbox.on('change', function() {
            checkStatus();
        });
        function checkStatus()
        {
            disableInput.find('.disable-input').each(function () {
                if(checkbox.is(':checked')) {
                    $(this).prop('readonly', true);
                } else {
                    $(this).prop('readonly', false);
                }
            })
        }
    }
}