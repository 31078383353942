$(function() {
    $(this).on('click', '.confirm', function(e) {
        e.preventDefault();
        let hideCancelButton = $(this).hasClass('hide-cancel-button');
        let title = $(this).data('confirm-title');
        title = title === undefined ? 'Achtung' : title;

        swal.fire({
            title: title,
            text: $(this).data('confirm-message'),
            showCancelButton: !hideCancelButton,
            confirmButtonColor: hideCancelButton ? '#b8cc97' :'#d9534f',
            cancelButtonColor: '#b8cc97',
            confirmButtonText: 'OK',
            cancelButtonText: 'Abbrechen'
        }).then((result) => {
            if (result.isConfirmed) {
                if ($(this).attr('href'))
                {
                    window.location = $(this).attr('href');
                }
                if ($(this).attr('type') === 'submit') {
                    let form = $(this).closest('form');
                    if (form.length > 0) {
                        form.submit();
                    }
                }
            }
        });
    });
});
