
(function ($) {
  'use strict';

  $(document).ready(function() {
    $('.init-select2').select2();
    $('.init-select2-no-search').select2({
      minimumResultsForSearch: -1
    });
  });

  /*======== 1. PREELOADER ========*/
  $(window).on('load', function () {
    $('#preloader').fadeOut(150);
  });

  /*======== 1. SMOOTH SCROLLING TO SECTION ========*/
  $('.scrolling  a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var target = $(this).attr('href');
    $('.navbar-collapse').removeClass('show');
    function customVeocity(set_offset) {
      $(target).velocity('scroll', {
        duration: 800,
        offset: set_offset,
        easing: 'easeOutExpo',
        mobileHA: false
      });
    }

    if ($('#body').hasClass('up-scroll') || $('#body').hasClass('static')) {
      customVeocity(2);
    } else {
      customVeocity(-90);
    }

  });
  
  $(document).ready(function() {
    $('.removeNav').on('click', function() {
      $('.dropdown').hide();
    });
  });

  $(document).ready(function($) {
    var n = function() {
      var ww = document.body.clientWidth;
      if (ww > 768) {
        $('.menuzord-menu').removeClass('remove-menuzord');
      } else if (ww <= 769) {
        $('.menuzord-menu').addClass('remove-menuzord');
      }
    };
    $(window).resize(function(){
      n();
    });
    //Fire it when the page first loads:
    n();
  });

  $(document).ready(function() {
    $('.scrollNav').on('click', function() {
      $('.remove-menuzord').hide();
    });
  });

  $(function(){
		$('.navComponents a').filter(function(){return this.href==location.href}).parent().addClass('active').siblings().removeClass('active')
		$('.navComponents a').click(function(){
			$(this).parent().addClass('active').siblings().removeClass('active')
		})
  });
  
  /*======== 2. NAVBAR ========*/
  $(window).on('load', function () {

    var header_area = $('.header');
    var main_area = header_area.find('.nav-menuzord');
    var zero = 0;
    var navbarSticky = $('.nav-navbar-sticky');

    $(window).scroll(function () {
      var st = $(this).scrollTop();
      if (st > zero) {
        navbarSticky.addClass('navbar-scrollUp');
      } else {
        navbarSticky.removeClass('navbar-scrollUp');
      }
      zero = st;

      if (main_area.hasClass('navbar-sticky') && ($(this).scrollTop() <= 600 || $(this).width() <= 300)) {
        main_area.removeClass('navbar-scrollUp');
        main_area.removeClass('navbar-sticky').appendTo(header_area);
        header_area.css('height', 'auto');
      } else if (!main_area.hasClass('navbar-sticky') && $(this).width() > 300 && $(this).scrollTop() > 600) {
        header_area.css('height', header_area.height());
        main_area.addClass('navbar-scrollUp');
        main_area.css({ 'opacity': '0' }).addClass('navbar-sticky');
        main_area.appendTo($('body')).animate({ 'opacity': 1 });
      }
    });

    $(window).trigger('resize');
    $(window).trigger('scroll');
  });

  /* ======== ALL DROPDOWN ON HOVER======== */
  if ($(window).width() > 991) {
    $('.navbar-expand-lg .navbar-nav .dropdown').hover(function () {
      $(this).addClass('').find('.dropdown-menu').addClass('show');
    }, function () {
      $(this).find('.dropdown-menu').removeClass('show');
    });
  }

  if ($(window).width() > 767) {
    $('.navbar-expand-md .navbar-nav .dropdown').hover(function () {
      $(this).addClass('').find('.dropdown-menu').addClass('show');
    }, function () {
      $(this).find('.dropdown-menu').removeClass('show');
    });
  }

  //============================== Menuzord =========================
  $('#menuzord').menuzord({
    indicatorFirstLevel: '<i class="fa fa-angle-down"></i>',
    indicatorSecondLevel: '<i class="fa fa-angle-right"></i>'
  });


})(jQuery);