/*
* data-modal-content-url: Url for Modal Content
* data-refresh-content-url: Url for page content to refresh after save
* data-refresh-content-id: id of element to replace content from content refresh url
*
* <button class="btn btn-sm btn-primary btn-modal-form"
*       data-refresh-content-url="/backoffice/veranstalter/tab/basis/951"
*       data-refresh-content-id="bases-card"
*       data-modal-content-url="/backoffice/veranstalter/basis/bearbeiten/1653">
*       bearbeiten
* </button>
*
* <button class="btn btn-sm btn-primary btn-modal-form"
*       data-refresh-content-id="bases-card"
*       data-modal-content-url="/backoffice/veranstalter/basis/bearbeiten/1653">
*       bearbeiten
* </button>
*
* <button class="btn btn-sm btn-primary btn-modal-form"
*       data-modal-content-url="/backoffice/veranstalter/basis/bearbeiten/1653">
*       bearbeiten
* </button>
* */

import $ from "jquery";

$(function () {
    let modalEditForm = $('#modal-edit-form');
    let modalDialog = modalEditForm.find('.modal-dialog');

    let map;
    let markers = [];
    let mapId;
    let mapDefaultLat;
    let mapDefaultLon;
    let mapDefaultZoom;
    let idPosLat;
    let idPosLon;

    $(document).on('click', '.btn-modal-form', function () {
        btnModalForm(this)
    });

    function initMap(mapId, idPosLat, idPosLon, mapDefaultLat, mapDefaultLon, mapDefaultZoom) {
        let posLat = $('#'+idPosLat);
        let posLon = $('#'+idPosLon);
        const myLatlng = { lat: parseFloat(mapDefaultLat), lng: parseFloat(mapDefaultLon) };
        map = new google.maps.Map(document.getElementById(mapId), {
            zoom: mapDefaultZoom,
            center: myLatlng,
        });
        if (posLat.val() !== undefined && posLon.val() !== undefined) {
            let initLatLon = { lat: parseFloat(posLat.val()), lng: parseFloat(posLon.val()) }
            addMarker(initLatLon);
        }
        // Configure the click listener.
        map.addListener("click", (event) => {

            if (markers.length >= 1) {
                deleteMarker();
            }
            addMarker(event.latLng);
            posLat.val(event.latLng.lat);
            posLon.val(event.latLng.lng);
        });
    }

    function addMarker(position) {
        const marker = new google.maps.Marker({
            position,
            map,
        });
        markers.push(marker);
    }

    function setMapOnAll(map) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    // Deletes all markers in the array by removing references to them.
    function deleteMarker() {
        setMapOnAll(null);
        markers = [];
    }

    $(document).on('submit', '.modal-form', function (event) {
        var submitButton = $(this).find(':submit');
        submitButton.attr('disabled', true);
        if (submitButton.data('modal-submit') !== "undefined" && submitButton.data('modal-submit') === true) {
            return 0;
        }
        event.preventDefault();
        submitButton.attr("disabled", true);
        $.ajax({
            type: 'POST',
            url: $(this).prop('action'),
            data: new FormData(this),
            contentType:false,
            processData:false,
            cache:false,
            success: function success(result) {

                if ($(result).has('.form-error-message').length === 0 && $(result).has('.is-invalid').length === 0 && (submitButton.data('modal-autoclose') === undefined || submitButton.data('modal-autoclose') === true)) {
                    modalEditForm.find('.modal-header').html('');
                    modalEditForm.find('.modal-body').html('');
                    modalEditForm.modal('hide');
                }
                //flash messages
                modalDialog.html(result);
                if (mapId !== undefined) {
                    initMap(mapId, idPosLat, idPosLon, mapDefaultLat, mapDefaultLon, mapDefaultZoom);
                }
                $('.select2modal').select2();
            },
            error: function error(result) {

            }
        });
    })

    window.refreshContent = function(editButton) {
        let refreshContentUrl = $(editButton).data('refresh-content-url');
        let content = $('#'+$(editButton).data('refresh-content-id'));
        $.ajax({
            url: refreshContentUrl,
            success: function (result) {
                $(content).html(result);
            }
        });
    }

    $(document).on('click', '.btn-ajax-action', function (e) {
        e.preventDefault();
        if ($(this).data('confirm-message') === undefined)
        {
            ajaxAction(this);
        } else {
            swal.fire({
                title: 'Achtung',
                html: $(this).data('confirm-message'),
                showCancelButton: true,
                confirmButtonColor: '#d9534f',
                cancelButtonColor: '#b8cc97',
                confirmButtonText: 'OK',
                cancelButtonText: 'Abbrechen'
            }).then((result) => {
                if (result.value) {
                    ajaxAction(this);
                }
            });
        }
        function ajaxAction(button)
        {
            let editButton = $(button);
            let actionUrl = $(button).data('action-url');
            $.ajax({
                url: actionUrl,
                success: function (result) {
                    let refreshContentId = '#'+$(editButton).data('refresh-content-id');
                    let content = $(refreshContentId);
                    if (content.length > 0)
                    {
                        if ($(editButton).data('refresh-content-url') !== undefined)
                        {
                            refreshContent(editButton);
                        } else {
                            if (editButton.hasClass('add-back')) {
                                content.html($(result).find(refreshContentId).addBack(refreshContentId));
                            } else {
                                content.html(result);
                            }
                            $('[data-toggle="popover"]').popover();
                            $('[data-toggle="tooltip"]').tooltip();
                        }
                    }
                    if (editButton.hasClass('btn-single-use')) {
                        editButton.addClass("btn-outline-secondary disabled")
                    }
                }
            });
        }
    });

    window.btnModalForm = function(btnModalForm) {
        let modalContentUrl = $(btnModalForm).data('modal-content-url');
        let modalSizeClass = $(btnModalForm).data('modal-size-class');
        mapId = $(btnModalForm).data('map-id');
        mapDefaultLat = $(btnModalForm).data('default-lat');
        mapDefaultLon = $(btnModalForm).data('default-lon');
        mapDefaultZoom = $(btnModalForm).data('default-zoom');
        idPosLat = $(btnModalForm).data('input-lat');
        idPosLon = $(btnModalForm).data('input-lon');

        let editButton = $(btnModalForm);
        if (modalSizeClass !== undefined) {
            modalDialog.addClass(modalSizeClass);
        }
        modalEditForm.on('hidden.bs.modal', function () {
            $(btnModalForm).find('.modal-header').html('');
            $(btnModalForm).find('.modal-body').html('');
            if (modalSizeClass !== undefined) {
                modalDialog.removeClass(modalSizeClass);
            }
            refreshContent(editButton)
            modalEditForm.off('hidden.bs.modal');
        })

        $.ajax({
            url: modalContentUrl,
            success: function (result) {
                modalDialog.html(result);
                if (editButton.data('backdrop')) {
                    modalEditForm.modal({
                        backdrop: editButton.data('backdrop'),
                    });
                }
                modalEditForm.modal('show');
                initDatePicker();
                if (mapId !== undefined) {
                    initMap(mapId, idPosLat, idPosLon, mapDefaultLat, mapDefaultLon, mapDefaultZoom);
                }
                $('[data-toggle="tooltip"]').tooltip();
            },
            error: function () {
                location.reload();
            }
        });
    }
});