import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { useIntersection } from 'stimulus-use'

export default class extends Controller {

    static targets = [
        'content'
    ]

    static values = {
        dataUrl: String,
    }

    connect() {
        this.isInitialized = false;
        useIntersection(this);
    }

    appear() {
        if (!this.isInitialized)
        {
            this.loadData(this.dataUrlValue, this.contentTarget);
        }
    }

    loadData(dataUrl, target) {
        $.ajax({
            type: "GET",
            url: dataUrl,
            success: function(data) {
                target.innerHTML = data;
                $('[data-toggle="popover"]').popover();
                $('[data-toggle="tooltip"]').tooltip();
            }
        });
    }
}
