$(function() {
    let visible = false;
    let offsetBottom = 300;
    let offsetTop = 400;
    let bubble = $('.fixed-bubble');
    let bubbleText = $('.fixed-bubble-text');
    bubble.hide(0);
    bubbleText.hide(0);
    checkVisibility();
    $(window).scroll(function() {
        checkVisibility();
    });
    function checkVisibility() {
        let position = $(window).height() + $(window).scrollTop();
        let bottomHeightOffset = $('body').height() - offsetBottom;

        if (visible && (bottomHeightOffset <= position || $(window).scrollTop() <= offsetTop)) {
            visible = false;
            bubble.hide(400);
            bubbleText.hide(0);
        }
        if (!visible && (bottomHeightOffset > position && $(window).scrollTop() > offsetTop)) {
            visible = true;
            bubble.show(0);
            bubbleText.show(400);
        }
    }
});