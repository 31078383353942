import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static values = {
        buttonContainerId: String,
        title: String,
        mailUrl: String,
        mailBody: String,
        mailSubject: String,
    }

    connect() {
        let Shariff = require('shariff');
        let buttonContainer = $('#'+this.buttonContainerIdValue);
        let title = this.titleValue;
        let mailUrl = this.mailUrlValue;
        let mailBody = this.mailBodyValue;
        let mailSubject = this.mailSubjectValue;

        let shar = new Shariff(buttonContainer, {
            services: ['facebook', 'twitter', 'whatsapp', 'pinterest', 'mail'],
            theme: 'grey',
            buttonStyle: 'icon',
            orientation: 'horizontal',
            title: title,
            mailUrl: mailUrl,
            mailBody: mailBody,
            mailSubject: mailSubject,
        });
    }
}